import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Base } from './Base';
import { Client } from './Client';
import { Industry } from './Industry';
import { Service } from './Service';
import { Testimonial } from './Testimonial';

export interface KeyResult {
  prefix: string | null,
  value: number,
  suffix: string | null,
  label: string,
}

export class CaseStudy extends Base {
  constructor(
    public title: string,
    public slug: string,
    public content?: string,
    public image?: IGatsbyImageData,
    public copyBlock1?: string,
    public copyBlock2?: string,
    public copyBlock3?: string,
    public keyResults?: KeyResult[],
    public linkedClients?: Client[],
    public linkedIndustries?: Industry[],
    public linkedTestimonials?: Testimonial[],
    public linkedServices?: Service[],
  ) { super(); }
}
