import React from 'react';
import { graphql } from 'gatsby';
import SEO from 'lib/components/Seo';
import Container from 'src/components/Container';
import Block from 'components/Block';
import styled from 'styled-components';
import PageHeader from 'src/components/PageHeader';
import Breadcrumbs, { Props as BreadcrumbsProps } from 'src/components/Breadcrumbs';
import FilteringToggle, { FilteringItem, Props as FilteringToggleProps } from 'src/components/FilteringToggle';
import CaseStudiesBlock from 'src/components/CaseStudiesBlock';
import CaseStudyQuery_CaseStudy from 'src/transformers/CaseStudyQuery_CaseStudy';
import isNotNull from 'src/types/guards/isNotNull';
import Spacer from 'src/components/Spacer';
import BasicQuery_SitePage from 'src/transformers/BasicQuery_SitePage';

export const query = graphql`
  query CaseStudiesPage($pageId: String) {
    wpPage(id: {eq: $pageId}) {
      id
      title
      slug
      content
      acfSeo {
        metaDescription
      }
    }
    allWpCaseStudy(sort: {order: ASC, fields: menuOrder}) {
      nodes {
        ...CaseStudyWithThumbnail
        ...CaseStudyWithClients
        ...CaseStudyWithIndustries
      }
    }
  }
`;


interface Props {
  data: GatsbyTypes.CaseStudiesPageQuery,
}


const CaseStudiesMultiPage:React.FC<Props> = ({ data }) => {
  const pageData = BasicQuery_SitePage(data.wpPage || {});
  const [activeFilteringId, setActiveFilteringId] = React.useState<string>();
  const breadcrumbs: BreadcrumbsProps['items'] = [{ label: 'Case Studies' }];

  const caseStudiesRaw = data.allWpCaseStudy.nodes || [];
  const caseStudies = Array.from(caseStudiesRaw, CaseStudyQuery_CaseStudy).filter(isNotNull);
  const caseStudiesFiltered = caseStudies.filter((caseStudy) => (
    activeFilteringId === 'all' ||
    caseStudy?.linkedIndustries?.find((industry) => industry.slug === activeFilteringId)
  ));

  const filteringItems: FilteringItem[] = [{
    id: 'all',
    label: 'All',
  }];
  data.allWpCaseStudy.nodes.forEach((caseStudy) => {
    caseStudy.acf?.linkedIndustries?.forEach((industryRaw) => {
      const industry = BasicQuery_SitePage(industryRaw || {});
      if (!filteringItems.find(({ id }) => industry.slug === id)) {
        filteringItems.push({
          id: industry.slug,
          label: industry.navTitle,
        });
      }
    });
  });

  const handleFilteringChange: FilteringToggleProps['onChange'] = (id) => setActiveFilteringId(id);
  return (
    <CaseStudiesPageOuter>
      <SEO
        title={pageData.metaTitle}
        description={pageData.metaDescription}
      />
      <PageHeader
        category={filteringItems.find((item) => item.id === activeFilteringId)?.label}
        h1={pageData.h1}
        backgroundText={pageData.contentHtml}
      />
      <Container>
        <Breadcrumbs items={breadcrumbs} />
        <Spacer mb={3} />
        <Block type="noPadding">
          <FilteringToggle
            onChange={handleFilteringChange}
            items={filteringItems}
            activeId={activeFilteringId}
          />
        </Block>
        {caseStudies.length > 0 && (
          <CaseStudiesBlock title="" caseStudies={caseStudiesFiltered} />
        )}
      </Container>
    </CaseStudiesPageOuter>
  );
};
export default CaseStudiesMultiPage;

const CaseStudiesPageOuter = styled.div``;
