import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Base } from './Base';

export class Testimonial extends Base {
  constructor(
    public name: string,
    public slug: string,
    public quoteCopyHTML: string,
    public jobTitle?: string,
    public image?: IGatsbyImageData,
  ) {
    super();
  }
}
