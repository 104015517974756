import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Testimonial } from 'models/Testimonial';

interface RequiredInput {
  [key:string]: any,
  title?: string,
  slug?: string,
  content?: string,
  acf?: {
    jobTitle?: string,
  },
  featuredImage?: {
    node?: {
      localFile?: {
        childImageSharp?: {
          gatsbyImageData?: IGatsbyImageData,
        }
      }
    }
  }
}

export default (
  queryData?: RequiredInput,
): Testimonial | null => {
  if (!queryData) return null;
  return new Testimonial(
    queryData?.title || '',
    queryData?.slug || '',
    queryData?.content || '',
    queryData?.acf?.jobTitle || '',
    queryData?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
  );
};
