import { CaseStudy, KeyResult } from 'models/CaseStudy';
import { AcfSeo } from 'src/types';
import isNotNull from 'src/types/guards/isNotNull';
import ClientQuery_Client from './ClientQuery_Client';
import IndustryQuery_Industry from './IndustryQuery_Industry';
import ServiceQuery_Service from './ServiceQuery_Service';
import TestimonialQuery_Testimonial from './TestimonialQuery_Testimonial';

type Maybe<T> = T | undefined;

interface RequiredInput {
  [key:string]: any,
  title: GatsbyTypes.WpCaseStudy['title'],
  slug: GatsbyTypes.WpCaseStudy['slug'],
  content: GatsbyTypes.WpCaseStudy['content'],
  featuredImage?: GatsbyTypes.FeaturedImageThumbnailFragment,
  acf?: {
    readonly linkedIndustries?: ReadonlyArray<Maybe<GatsbyTypes.IndustryWithIconFragment>>,
    readonly linkedClients?: ReadonlyArray<Maybe<GatsbyTypes.ClientBasicFragment>>,
    readonly linkedTestimonials?: ReadonlyArray<Maybe<GatsbyTypes.TestimonialBasicFragment>>,
    readonly linkedServices?: ReadonlyArray<Maybe<GatsbyTypes.ServiceBasicFragment>>,
    keyResults?: KeyResult[],
    copyBlock1?: string,
    copyBlock2?: string,
    copyBlock3?: string,
  },
  acfSeo?: Partial<AcfSeo>,
}

export default (
  queryData?: RequiredInput,
): CaseStudy | null => {
  if (!queryData) return null;
  return new CaseStudy(
    queryData?.title || '',
    queryData?.slug || '',
    queryData?.content || '',
    queryData?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    queryData.acf?.copyBlock1,
    queryData.acf?.copyBlock2,
    queryData.acf?.copyBlock3,
    queryData.acf?.keyResults,
    Array.from(queryData?.acf?.linkedClients || [], ClientQuery_Client).filter(isNotNull),
    Array.from(queryData?.acf?.linkedIndustries || [], IndustryQuery_Industry).filter(isNotNull),
    Array.from(
      queryData?.acf?.linkedTestimonials || [],
      TestimonialQuery_Testimonial,
    ).filter(isNotNull),
    Array.from(
      queryData?.acf?.linkedServices || [],
      ServiceQuery_Service,
    ).filter(isNotNull),
  );
};
