import React from 'react';
import styled from 'styled-components';
import Button from 'components/Button';

export interface FilteringItem {
  id: string,
  label: string,
}

export interface Props {
  items: FilteringItem[],
  onChange: (id: string) => void,
  activeId?: string,
}

const FilteringToggle:React.FC<Props> = ({
  items,
  onChange,
  activeId: activeItemIdProp,
}) => {
  const [activeItemIdState, setActiveItemIdState] = React.useState(items[0].id);
  const activeItemId = activeItemIdProp || activeItemIdState;

  const handleClick = (id: string) => {
    setActiveItemIdState(id);
    onChange(id);
  };

  React.useEffect(() => {
    onChange(activeItemIdState);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FilteringToggleOuter>
      <Content>
        {items.map(({ id, label }) => (
          <ButtonWrap key={id}>
            <ButtonStyled
              onClick={() => handleClick(id)}
              disabled={activeItemId === id}
              className={activeItemId === id ? 'disabled' : ''}
              borderless
              background="transparent"
              type="clear"
            >
              <BoldLabel>{label}</BoldLabel>
              <NormalLabel>{label}</NormalLabel>
            </ButtonStyled>
          </ButtonWrap>
        ))}
      </Content>
    </FilteringToggleOuter>
  );
};
export default FilteringToggle;

const FilteringToggleOuter = styled.div``;
const Content = styled.div`
  position: relative;
  border: 1px solid ${(p) => p.theme.palette.interactive};
  border-width: 1px 0;
  margin: 0 0 10px;
  ${({ theme }) => theme.breakpoints.up('phoneWide')} {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5em;
    border: none;
  }
`;
const ButtonWrap = styled.div`
  display: flex;
  ${({ theme }) => theme.breakpoints.up('phoneWide')} {
    padding: 10px;
  }
`;
const ButtonStyled = styled(Button)`
  position: relative;
  appearance: none;
  flex: 1 0 auto;
  font-weight: normal;
  &&.disabled {
    color: ${(p) => p.theme.palette.interactive};
    opacity: 1;
  }
`;
const NormalLabel = styled.span`
  ${ButtonStyled}.disabled & {
    opacity: 0;
  }
`;
const BoldLabel = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: bold;
  opacity: 0;
  ${ButtonStyled}.disabled & {
    opacity: 1;
  }
`;
