import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Base } from './Base';
import type { Industry } from './Industry';
import type { Service } from './Service';

export class Client extends Base {
  constructor(
    public name: string,
    public slug: string,
    public image?: IGatsbyImageData,
    public contentHTML?: string,
    public externalUrl?: string,
    public linkedServices?: Service[],
    public linkedIndustries?: Industry[],
  ) {
    super();
  }
}
