import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Client } from 'models/Client';

interface RequiredInput {
  [key:string]: any,
  title: GatsbyTypes.WpClient['title'],
  slug: GatsbyTypes.WpClient['slug'],
  content: GatsbyTypes.WpClient['content'],
  acf?: {
    url?: {
      url?: string,
    }
  },
  featuredImage?: {
    node?: {
      localFile?: {
        childImageSharp?: {
          gatsbyImageData?: IGatsbyImageData,
        }
      }
    }
  }
}

export default (
  queryData?: RequiredInput,
): Client | null => {
  if (!queryData) return null;
  return new Client(
    queryData?.title || '',
    'slug: ',
    queryData?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    queryData?.content,
    queryData?.acf?.url?.url,
  );
};
